import {
  Introspector,
  IntrospectorTypes,
  IIntrospectorEnumObject,
  IIntrospectorDataObject,
  IIntrospectorAdtObject,
  IIntrospectorType,
  IIntrospectorUserType,
  IIntrospectorIdObject,
} from '@playq/irt';
import { Formula, OperandLiteralBoolean } from '@playq/octopus-common';

import { zeroGuid } from '/constants';

interface IIDLModel {
  getPackageName(): string;
  getClassName(): string;
  getFullClassName(): string;
}

export function randomOption<T>(options: T[]) {
  return options[Math.floor(Math.random() * options.length)];
}

export function randomOptionArray<T>(options: T[], limit = 2) {
  const len = randomInt(limit);
  const res: T[] = [];
  for (let i = 0; i < len; i++) {
    res.push(randomOption<T>(options));
  }
  return res;
}

export function randomBoolean() {
  return Math.random() >= 0.5;
}

export function randomInt(limit = 100) {
  return Math.floor(Math.random() * limit + 1);
}

export function uniqueRandomInt(min = 0, max = 1) {
  let previousValue: number;
  return function random(): number {
    const num = Math.floor(Math.random() * (max - min + 1) + min);
    previousValue = num === previousValue && min !== max ? random() : num;
    return previousValue;
  };
}

export function randomFloat() {
  return Math.random() * 100;
}

export function randomString() {
  const len = Math.floor(Math.random() * 5 + 1);
  let text = '';
  const possible = ['Alpha', 'Beta', 'Gamma', 'Teta', 'Some', 'Random', 'Name', 'Possible', 'Red', 'Blue', 'Green'];

  for (let i = 0; i < len; i++) {
    text += (i === 0 ? '' : ' ') + possible[Math.floor(Math.random() * possible.length)];
  }

  return text;
}

export function randomStringWithoutSpaces() {
  return randomString().replaceAll(' ', '');
}

export function randomDate() {
  const dt = new Date();
  dt.setTime(dt.getTime() - 1000 * 60 * 60 * randomInt(10000));
  return dt;
}

export function randomGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
/* eslint-disable @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/switch-exhaustiveness-check */

function canResetType(t: IIntrospectorType) {
  switch (t.intro) {
    case IntrospectorTypes.Service:
    case IntrospectorTypes.Method:
    case IntrospectorTypes.Mixin:
      return false;
    default:
      return true;
  }
}

function getIntroType(introType: IIntrospectorType) {
  switch (introType.intro) {
    case IntrospectorTypes.Service:
    case IntrospectorTypes.List: {
      // const tl = introType as IIntrospectorGenericType;
      // tl.value has a type
      return [];
    }

    case IntrospectorTypes.Map: {
      // const tm = introType as IIntrospectorMapType;
      // tm.value and tm.key have types
      return {};
    }

    case IntrospectorTypes.Data: {
      const td = introType as IIntrospectorUserType;
      const io = Introspector.find(td.full) as IIntrospectorDataObject;

      return io.ctor();
    }

    default:
      throw new Error(`Unsupported intro type: ${JSON.stringify(introType)}`);
  }
}

function getDefaultType(t: IIntrospectorType, random = false) {
  switch (t.intro) {
    case IntrospectorTypes.Bool:
      return random ? randomBoolean() : false;

    case IntrospectorTypes.Date:
    case IntrospectorTypes.Time:
    case IntrospectorTypes.Tsl:
    case IntrospectorTypes.Tsu:
    case IntrospectorTypes.Tsz:
      return random ? randomDate() : new Date();

    case IntrospectorTypes.F32:
    case IntrospectorTypes.F64:
      return random ? randomFloat() : 0.0;

    case IntrospectorTypes.I08:
    case IntrospectorTypes.U08:
      return random ? randomInt(120) : 0;

    case IntrospectorTypes.I16:
    case IntrospectorTypes.U16:
      return random ? randomInt(32000) : 0;

    case IntrospectorTypes.I32:
    case IntrospectorTypes.U32:
    case IntrospectorTypes.I64:
    case IntrospectorTypes.U64:
      return random ? randomInt(100000) : 0;

    case IntrospectorTypes.Uid:
      return random ? randomGuid() : zeroGuid;
    case IntrospectorTypes.Opt:
      return undefined;

    case IntrospectorTypes.Str:
      return random ? randomString() : '';

    case IntrospectorTypes.List:
    case IntrospectorTypes.Set:
      return [];

    case IntrospectorTypes.Map:
      return {};

    case IntrospectorTypes.Enum: {
      const tu = t as IIntrospectorUserType;
      const te = Introspector.find(tu.full) as IIntrospectorEnumObject;
      return random ? randomOption(te.options) : te.options[0];
    }

    case IntrospectorTypes.Data: {
      const tu = t as IIntrospectorUserType;
      const td = Introspector.find(tu.full) as IIntrospectorDataObject;
      const dataInstance = td.ctor();
      resetModel(dataInstance, random);
      return dataInstance;
    }

    case IntrospectorTypes.Adt: {
      const tu = t as IIntrospectorUserType;
      const ta = Introspector.find(tu.full) as IIntrospectorAdtObject;
      const first = random ? ta.options[Math.floor(Math.random() * ta.options.length)] : ta.options[0];
      return getIntroType(first.type);
    }

    case IntrospectorTypes.Id: {
      const tu = t as IIntrospectorUserType;
      const ti = Introspector.find(tu.full) as IIntrospectorIdObject;
      const idInstance = ti.ctor();
      resetModel(idInstance, random);
      return idInstance;
    }

    // We could potentially find a single implementation and create it
    // case IntrospectorTypes.Mixin: {
    //   const tu = t as IIntrospectorUserType;
    //   const tm = Introspector.find(tu.full) as IIntrospectorMixinObject;
    //   const implFirst = tm.implementations()[0];
    //   const ti = Introspector.find(implFirst) as IIntrospectorDataObject;
    //   const implInstance = ti.ctor();
    //   resetModel(implInstance);
    //   return implInstance;
    // }

    // case IntrospectorTypes.Service: // Not possible
    // case IntrospectorTypes.Method: // Not possible
  }
}

export function resetModel(m: IIDLModel, random = false) {
  const intro = Introspector.find(m.getFullClassName());
  switch (intro.type) {
    case IntrospectorTypes.Id:
    case IntrospectorTypes.Data:
      {
        const id = intro as IIntrospectorDataObject | IIntrospectorIdObject;
        id.fields.forEach((f) => {
          if (!canResetType(f.type)) {
            return;
          }

          // We don't know field names in advance, so we use introspection to process it
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-explicit-any
          (m as any)[f.accessName] = getDefaultType(f.type, random);
        });
      }
      break;
  }
}

export function defaultFormula() {
  const newFormula = new Formula();
  newFormula.raw = 'true';
  newFormula.expression = new OperandLiteralBoolean({
    value: true,
    origin: undefined,
  });

  return newFormula;
}
