import { fromEvent, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

export function createHiddenInput(multiple = true): HTMLInputElement {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');

  if (multiple) {
    input.setAttribute('multiple', '');
  }

  input.setAttribute('data-testid', 'native-file-picker');
  input.setAttribute('styles', '{ display: none }');
  input.className = 'native-file-picker';

  document.body.appendChild(input);

  return input;
}

export function nativeFilePicker$(multiple = false): Observable<File[]> {
  const prevInput = document.getElementsByClassName('native-file-picker')[0];
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (prevInput) {
    document.body.removeChild(prevInput);
  }
  let input: HTMLInputElement | null = createHiddenInput(multiple);
  const event$ = fromEvent(input, 'change').pipe(
    take(1),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
    map((event: any) => event.target.files),
    filter((files: FileList) => !!files?.length),
    map((files: FileList) => Array.from(new Array(files.length)).map((_, index: number) => files.item(index) as File)),
    tap(() => {
      document.body.removeChild(input as HTMLInputElement);
      input = null;
    })
  );

  input.click();

  return event$;
}
