import { Observable, Subject } from 'rxjs';
import _ from 'lodash';
import { VariantType } from 'notistack';

import { GenericFailure } from '@playq/services-shared';

import {
  SNACKBAR_STORAGE_MAX_SIZE,
  snackbarStorage,
  snackbarStorageKey,
} from '/storage/sessionStorage/snackBarHistory';
import { pageLink, pageTitleKey, pageTitleStorage } from '/storage/sessionStorage/currentModule';
import { getErrorMessage } from '/helpers/getErrorMessage';

import { ICustomSnackbarProps, IDefaultSnackBarHistoryItem, ISnackbarProps } from './SnackBar';

export class SnackbarService {
  private snackbarSubject = new Subject<ISnackbarProps>();

  success<A>(msg: string, action?: A, persist?: boolean) {
    this.open({ msg, action, type: 'success', persist });
  }

  info<A>(msg: string, action?: A, persist?: boolean) {
    this.open({ msg, action, type: 'info', persist });
  }

  warning<A>(msg: string, action?: A, persist?: boolean) {
    this.open({ msg, action, type: 'warning', persist });
  }

  error<A>(msg: string, action?: A, persist?: boolean) {
    this.open({ msg, action, type: 'error', persist });
  }

  genericFailure<A>(err: GenericFailure | Error | string, action?: A, persist?: boolean) {
    if (typeof err === 'string') {
      this.open({ msg: err, action, type: 'error', persist });
      return;
    }
    this.open({ msg: getErrorMessage(err) || 'Network error', action, type: 'error', persist });
  }

  default<A>(msg: string, action?: A, persist?: boolean) {
    this.open({ msg, action, type: 'default', persist });
  }

  custom({ msg, customAction, type, persist, buttonText, dataTestId }: ICustomSnackbarProps) {
    this.open({ msg, customAction, type, persist, dataTestId, buttonText });
  }

  private open(props: ISnackbarProps) {
    this.snackbarSubject.next(props);
  }

  get snackbars$(): Observable<ISnackbarProps> {
    return this.snackbarSubject.asObservable();
  }
}

/**
 * `SnackbarServiceLogger` extends `SnackbarService` to log notifications into session storage.
 * It limits the number of stored logs to a maximum (defined by `SNACKBAR_STORAGE_MAX_SIZE`).
 *
 * - Logs are saved in JSON format as `IDefaultSnackBarHistoryItem`, which includes:
 *   method, msg, type, module (page title), moduleLink, timestamp, and error (if applicable).
 * - It logs notifications triggered by various `SnackbarService` methods, normalizes the log format,
 *   and automatically removes the oldest logs if the maximum limit is reached.
 */
class SnackbarServiceLogger extends SnackbarService {
  private readonly maxSnackbarLogs = SNACKBAR_STORAGE_MAX_SIZE;

  constructor() {
    super();
    this.initSessionStorage();
    return this.createMethodInterceptor();
  }

  // Initialize storage if not present
  initSessionStorage(): void {
    const logs = snackbarStorage.get(snackbarStorageKey);
    if (!logs) {
      snackbarStorage.set(snackbarStorageKey, JSON.stringify([]));
    }
  }

  // Helper function to log service calls with page title using the snackbarStorage
  logServiceCall(method: keyof SnackbarService, args: unknown[]): void {
    let snackbarLogs: IDefaultSnackBarHistoryItem[] = JSON.parse(
      snackbarStorage.get(snackbarStorageKey) || '[]'
    ) as IDefaultSnackBarHistoryItem[];

    // Get the module name from the page title storage
    const pageTitle: string | null = pageTitleStorage.get(pageTitleKey);
    const moduleLink: string | null = pageTitleStorage.get(pageLink);

    // Normalize and log the snackbar call
    const normalizedLog = this.normalizeSnackbarProps(method, args, pageTitle, moduleLink);
    snackbarLogs.push(normalizedLog);

    // Check if the log count exceeds the maximum limit
    if (snackbarLogs.length > this.maxSnackbarLogs) {
      snackbarLogs = snackbarLogs.slice(snackbarLogs.length - this.maxSnackbarLogs); // Remove oldest logs
    }

    snackbarStorage.set(snackbarStorageKey, JSON.stringify(snackbarLogs));
  }

  // Normalize snackbar properties into a consistent structure and include the page title
  normalizeSnackbarProps(
    method: keyof SnackbarService,
    args: unknown[],
    pageTitle: string | null,
    moduleLink: string | null
  ): IDefaultSnackBarHistoryItem {
    const [firstArg] = args as [string | ICustomSnackbarProps | Error | GenericFailure, unknown, boolean | undefined];

    // Default output structure
    const logEntry: IDefaultSnackBarHistoryItem = {
      method,
      msg: undefined,
      type: undefined,
      module: pageTitle ?? 'Unknown',
      moduleLink: moduleLink ?? 'Unknown',
      timestamp: new Date().toISOString(),
      error: null, // Default to null
    };

    if (method === 'custom' && typeof firstArg === 'object' && !('message' in firstArg)) {
      // For custom snackbars (ICustomSnackbarProps)
      const { msg, type } = firstArg;
      return {
        ...logEntry,
        msg,
        type,
      };
    } else if (method === 'genericFailure' && (firstArg instanceof Error || firstArg instanceof GenericFailure)) {
      // Handle errors for 'genericFailure'
      const error = firstArg;
      const msg = error.message || 'An error occurred';
      return {
        ...logEntry,
        msg,
        type: 'error',
        error, // Log the error
      };
    } else {
      // For default snackbars (IDefaultSnackbarProps)
      const msg = typeof firstArg === 'string' ? firstArg : 'Unknown';
      const type = method === 'genericFailure' ? 'error' : method;

      return {
        ...logEntry,
        msg,
        type: type as VariantType,
      };
    }
  }

  // Create an interceptor that dynamically wraps all methods
  createMethodInterceptor(): SnackbarServiceLogger {
    const handler: ProxyHandler<this> = {
      get: (target, propKey: string | symbol) => {
        const originalMethod = target[propKey as keyof this];

        // Check if the property is a function
        if (typeof originalMethod === 'function') {
          return (...args: unknown[]) => {
            if (typeof propKey === 'string' && propKey in target) {
              this.logServiceCall(propKey as keyof SnackbarService, args); // Log the call
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return originalMethod.apply(target, args); // Call the original method
          };
        }

        // Otherwise, return the original property value
        return originalMethod;
      },
    };

    return new Proxy(this, handler);
  }
}

// Replace the original snackbarService with the wrapped one
export const snackbarService = new SnackbarServiceLogger();

export function logSnackbarError(error: unknown, logToConsole = true) {
  const isErrorObj = (err: unknown): err is { message: string } =>
    _.isPlainObject(err) && typeof (err as Error).message === 'string';

  if (isErrorObj(error)) {
    snackbarService.error(error.message);
  } else if (typeof error === 'string') {
    snackbarService.error(error);
  } else {
    snackbarService.error('An error of unknown type was thrown, see details in a console');
    console.error(error);

    return;
  }

  if (logToConsole) {
    console.error(error);
  }
}
