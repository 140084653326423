import * as yup from 'yup';

import { JwtTokenUserSerialized } from '@playq/octopus2-auth';

export interface IJwtToken extends JwtTokenUserSerialized {
  exp: number;
  iat: number;
}

function dateValid(method: yup.MixedSchema): yup.MixedSchema {
  return method.test('ISOString', '${path} is not ISOString', (value) => new Date(value) instanceof Date);
}

function expSchema(method: yup.MixedSchema): yup.MixedSchema {
  return dateValid(method).test('Expiration', '${path} is expired', (value) => value > Date.now() / 1000);
}

export const baseTokenSchema = yup.object({
  exp: expSchema(yup.number()),
  iat: dateValid(yup.number()),
});
