import { defaultStorageKey } from '/constants';

import { localStorageMethods } from './localStorageMethods';
import { IStorage, IStorageDefinition, TimeUnit } from './types';

type EachItemCallback = (key: string, value: string | null) => void;

export const createStorage = (definition: Partial<IStorageDefinition> = {}): IStorage => {
  const expirationSuffix = '_expiration';
  const {
    timeUnit = TimeUnit.Hour,
    ttl = 168,
    prefix = defaultStorageKey,
    warnings = true,
    storage = localStorageMethods,
  } = definition;
  const cacheKeyRegExp = new RegExp(`^${prefix}(.*)`);

  /**
   * Creates cache key. by combining prefix + provided key
   * @param key string
   */
  const cacheKey = (key: string): string => {
    return prefix + key;
  };

  /**
   * Creates expiration cache key. by combining prefix + provided key + expiration key
   * @param key string
   */
  const expirationKey = (key: string): string => {
    return key + expirationSuffix;
  };

  /**
   * Creates expiration timestamp by Date.now, provided time
   * @param time
   */
  const expireTimestamp = (time: number): number => {
    return Date.now() + time * timeUnit;
  };

  /**
   * Check by the key if record is expired
   * @param key
   */
  const isExpired = (key: string): boolean => {
    const expKey = expirationKey(key);
    const expireData = storage.getItem(expKey);

    if (!expireData) {
      return false;
    }

    let result = false;
    try {
      const expire = parseInt(expireData, 10);
      result = Date.now() > expire;
    } catch (e) {
      if (warnings) {
        console.warn(`Can't parse expiration for: ${key}`);
      }
    }

    return result;
  };

  /**
   * Clear record + it expiration record
   * @param key
   */
  const flushItem = (key: string) => {
    const dataKey = cacheKey(key);
    const expireKey = expirationKey(dataKey);

    storage.removeItem(dataKey);
    storage.removeItem(expireKey);
  };

  /**
   * Set new data record by the key
   * @param key string
   * @param data unknown
   * @param time number. If time is provided will creates a support expiration record
   */
  const set = (key: string, value: string, time: number = ttl) => {
    flushExpired();

    if (!value) {
      return;
    }

    const dataKey: string = cacheKey(key);
    try {
      storage.setItem(dataKey, value);
    } catch (e) {
      if (warnings) {
        console.warn(`Can't set new item to storage.`, e);
      }
    }

    if (time) {
      const expKey = expirationKey(dataKey);
      const timestamp = expireTimestamp(time).toString();

      try {
        storage.setItem(expKey, timestamp);
      } catch (e) {
        if (warnings) {
          console.warn(`Can't set new item expiration to storage.`, e);
        }
      }
    }
  };

  /**
   * Retrieve record by the key
   * @param key string
   */
  const get = (key: string): string | null => {
    const dataKey = cacheKey(key);
    const expired = isExpired(dataKey);

    if (expired) {
      flushItem(key);
      return null;
    }

    return storage.getItem(dataKey);
  };

  /**
   * Remove record by the key
   * @param key string
   */
  const remove = (key: string) => {
    flushItem(key);
  };

  /**
   * Go through each records and call the callback
   * @param fn
   */
  const eachKey = (fn: EachItemCallback) => {
    storage.foreach((key: string, value: string | null) => {
      const matched = key?.match(cacheKeyRegExp);
      if (matched && !matched[1].includes(expirationSuffix)) {
        fn(key, value);
      }
    });
  };

  const removeItem = (key: string) => {
    const expKey = expirationKey(key);
    storage.removeItem(key);
    storage.removeItem(expKey);
  };

  /**
   * Clear all records
   */
  const flush = () => eachKey((key: string) => removeItem(key));

  /**
   * Clear all expired records
   */
  const flushExpired = () => eachKey((key: string) => isExpired(key) && removeItem(key));

  return {
    set,
    get,
    remove,
    flush,
    flushExpired,
  };
};
